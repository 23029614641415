import React, { useEffect, useState } from "react";
import { ComboBoxV2,Label,TextFieldV2 } from "@verint/shared-react-components";
import OverlayText from "../../Components/OverlayText";
import "@verint/lux_icon_font/verint_lux.css";
 
function CognitoGroup(props) {
  
  const [selectedOptionValue, setSelectedOption] = useState(props.cognitoGroupValue);
  const [cognitoGroupChanged, setCognitoGroupChanged] = useState(props.changedValue || '');
 
  useEffect(() => {
    const initialValue = props.cognitoGroupValue || '';
    setSelectedOption(initialValue);
    if (props.changedValue) {
      setCognitoGroupChanged(props.changedValue);
    }
  }, [props.changedValue, props.cognitoGroupValue]);
 
  const cognitoGroupsArray = JSON.parse(props.cognitoGroups);
  const optionsArray = cognitoGroupsArray.map((group) => ({
    value: group,
    key: group,
  }));
 
  const handleCognitoGroup = (selectedOption) => {
    setSelectedOption(selectedOption.value);
    setCognitoGroupChanged(selectedOption.value);
  };
  
  // is_superAdmin condition checking for Add Requester
  if (props.is_superAdmin === undefined || props.is_superAdmin) {
    // Continue rendering the component
  } else {
    return null;
  }

  // IsAdmin condition checking for Edit Form 
  if(props.item != null && !props.item.IsAdmin) {
    return null;
  }

  const selectedValue = optionsArray.find((o) => o.value === cognitoGroupChanged) || optionsArray.find((o) => o.value === selectedOptionValue) || optionsArray.find((o) => o.value === props.group);
  // const selectedValue =  optionsArray.find((o) => o.value === cognitoGroupChanged) ? optionsArray.find((o) => o.value === cognitoGroupChanged) : optionsArray.find((o) => o.value === selectedOptionValue);
  return (
    <>
    { props.addRequestForm && (
      <tr className='reqForm_tablerow'>
      <td className="td_row_leftvalue" >
           <Label className="verint-regular-label" forhtml="addRequest_cognitoGroup"  >Billing Dept </Label>
           <OverlayText tooltipText={"Select from the drop down the Cognito Groups for the lab."}></OverlayText>
       </td>
       <td className="td_row_rightvalue">
        <ComboBoxV2
          className="comboboxv2"
          name="addRequest_cognitoGroup"
          id="addRequest_cognitoGroup"
          popperProps={{open:true}}
          style={{ paddingRight: "7%" }}
          onChange={(_, value) => {
            handleCognitoGroup(value);
            }}
          optionKey={"key"}
          options={optionsArray}
          optionDisplay={"value"}
          value={selectedValue}
          textFieldProps={{                              
            placeholder: '--Select Cognito Groups--'
            }}
        />

        <TextFieldV2 style={{display:"none"}}                            
        id="addRequest_cognitoGroup"
        name='addRequest_cognitoGroup'
        data-testid="verint-textfield"
        defaultValue={selectedOptionValue}
        value={selectedOptionValue}
        className="verint-hidden-textfield"                                   
        />


      </td>
       </tr>
    ) }
    {
      !props.addRequestForm && (
        <tr className="editForm_table-row" style={{ border: "hidden", paddingBottom: "15px" }}>
        <td className="td_row_leftvalue">Cognito Groups</td>
        <td className="td_key" style={{ paddingLeft: "15px" }}>
          
        <ComboBoxV2
          className="comboboxv2"
          name="cognitoGroup"
          id="cognitoGroup"
          popperProps={{open:true}}
          style={{ paddingRight: "7%" }}
          onChange={(_, value) => {
            handleCognitoGroup(value);
            }}
          optionKey={"key"}
          options={optionsArray}
          optionDisplay={"value"}
          value={selectedValue}
          textFieldProps={{                              
            placeholder: '--Select Cognito Groups--'
            }}
        />
        </td>
      </tr> 
      )
    }
  </>
    
  );
}
 
export default CognitoGroup;
