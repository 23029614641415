import { Label, ComboBoxV2, Button } from "@verint/shared-react-components";
import "@verint/lux_icon_font/verint_lux.css";
import React, { useRef, useState, useEffect } from "react";
import "./currentRequest.css";

function SearchBar(props) {

  const [filterRow, setfilterRow] = useState([]);
  const [selectedServername, setSelectedServername] = useState("");
  const [selectedRequestor, setSelectedRequestor] = useState("");
  const [selectedEnvironment, setSelectedEnvironment] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false)

  const tableRows = props.myRequetsChecked ? props.myRequests : props.tableRows
  const serverName = get_servername(tableRows,selectedRequestor,selectedEnvironment);
  const requestors = get_requestors(tableRows,selectedServername,selectedEnvironment);
  const environments = get_environments(tableRows,selectedServername,selectedRequestor); 
  //const tableRows = props.tableRows;
  
  console.log("Requestors function return: ", requestors)

  const serverNameArray = Array.from(new Set(serverName.map(serverName => serverName.ServerName)))
  .map(serverName => ({
    key: serverName,
    value: serverName,
  }));

  const requestorsArray = Array.from(new Set(requestors.map(requestor => requestor.Requestor.toLowerCase())))
  .map(requestor => ({
    key: requestor,
    value: requestor,
  }));
  
  const environmentsArray = Array.from(new Set(environments.map(environment => environment.Environment)))
  .map(environment => ({
    key: environment,
    value: environment,
  }));
  // useEffect(() => {
  //   if (selectedServername) {
  //     console.log("set Requestor Array::", requestorsArray)
  //     const filteredRequestors = getRequestorsByServer(selectedServername);
  //     setRequestorsArray(filteredRequestors);
  //   }
  // }, [selectedServername]);


  const handleServerNameSearchChange = (event) => {
    console.log("handleServerNameSearchChange Event!!" + event.key);
    setSelectedServername(event.key);
  };

  const handleRequestorChange = (event) => {
    console.log("handleRequestorChange Event!!" + event.key);
    setSelectedRequestor(event.key);
  };

  const handleEnvironmentSearchChange = (event) => {
    console.log("handleEnvironmnetNameSearchChange Event!!" + event.key);
    setSelectedEnvironment(event.key);
  };


  const handleFilterSearch = (in_servername,in_requestor,in_environment) => {
    const filteredRows = tableRows.filter((row) =>
      row.ServerName.toLowerCase().includes(in_servername.toLowerCase()) &&
      row.Requestor.toLowerCase().includes(in_requestor.toLowerCase()) &&
      row.EnvironmentName.toLowerCase().includes(in_environment.toLowerCase())
    );
    console.log("handleFilterSearch filteredRows: ", filteredRows);
    setfilterRow(filteredRows);

  };

  const handleClearFilter = () => {
    setfilterRow([]);
    setSelectedServername("");
    setSelectedRequestor("");
    setSelectedEnvironment("");
    setForceUpdate(prev => !prev);
    props.onMyRequestsChange(false)
  };

  useEffect(() => {
    //console.log("filtered row valuesc UseEffect:: ", filterRow);
    props.onChangeFilterRow(filterRow);
  }, [filterRow]);

  return (
    <>
      <div className="SearchBarDiv">
        <Label
          id="SearchBar_label"
          className="verint-regular-label"
          forhtml="SearchBar"
        >
          Search For:
        </Label>

        <Label
          id="SearchBar_label"
          className="verint-regular-label"
          forhtml="SearchBar"
        >
          EnvironmentName:
        </Label>

        <ComboBoxV2
          className="comboboxv2"
          key={`environment-${forceUpdate}`}
          selectedKey={selectedEnvironment}
          optionKey="value"
          options={environmentsArray}
          optionDisplay={"value"}
          onChange={(_, value) => {
            handleEnvironmentSearchChange(value);
          }}          
          textFieldProps={{                               
              placeholder: '---- EnvironmentName ----'
            }}
          // placeholder="---- EnvironmentName ----"
          tooltipText=""
          
        />

        <Label
          id="SearchBar_label"
          className="verint-regular-label"
          forhtml="SearchBar"
        >
          Servername:
        </Label>

        <ComboBoxV2
          className="comboboxv2"
          key={`server-${forceUpdate}`}
          selectedKey={selectedServername}
          optionKey="value"
          options={serverNameArray}
          optionDisplay={"value"}
          onChange={(_, value) => {
            handleServerNameSearchChange(value);
          }}          
          textFieldProps={{                               
              placeholder: '-------- Servername --------'
            }}
          // placeholder="-------- Servername --------"
          tooltipText=""
          
        />

        <Label
          id="SearchBar_label"
          className="verint-regular-label"
          forhtml="SearchBar"
        >
          Requestor:
        </Label>

        <ComboBoxV2
          className="comboboxv2"
          key={`requestor-${forceUpdate}`}
          optionKey="value"
          options={requestorsArray}
          optionDisplay={"value"}
          onChange={(_, value) => {
            handleRequestorChange(value);
          }}          
          textFieldProps={{                               
              placeholder: '--------- Requestor ---------'
            }}
          // placeholder="--------- Requestor ---------"
          tooltipText=""
        />

        <div className="SearchButtonDiv">
          <div className="searchButton">
            <Button
              class="btn btn-primary form-control"
              data-testid="verint-button"
              onClick={() => handleFilterSearch(selectedServername, selectedRequestor, selectedEnvironment)}
              style={{ height: "20px", width: "80px", padding: "inherit", fontSize: "small"}}
            >
              Search
            </Button>
          </div>

          <div className="clearButton">
            <Button
              data-testid="verint-button"
              onClick={handleClearFilter}
              style={{
                height: "20px",
                width: "30px",
                background: "#999",
                fontSize: "small"
              }}
            >
              Clear
            </Button>
          </div>
        </div>
      </div>
      <br />
    </>
  );
}

function get_servername(in_tableRows,in_selectedRequestor,in_selectedEnvrionment) {
  //console.log("serverName array : (get_serverName):: ", props);
  var jsonData = in_tableRows;
  const serverNames = [];

  jsonData.forEach((index) => {
    if (in_selectedRequestor) {
      if (index.Requestor.toLowerCase() === in_selectedRequestor.toLowerCase()) {
        serverNames.push({
          ServerName: index.ServerName,
        });
      }
    }
    else if (in_selectedEnvrionment) {
      if (index.EnvironmentName === in_selectedEnvrionment) {
        serverNames.push({
          ServerName: index.ServerName,
        });
      }
    }
    else{
      serverNames.push({
        ServerName: index.ServerName,
        });
      }
    });
  //console.log("serverNames::::get_serverNames: ", serverNames);
  return serverNames;
}

function get_requestors(in_tableRows,in_selectedServername,in_selectedEnvrionment) {
  //console.log("requestors array : (get_requestors):: ", props);
  var jsonData = in_tableRows;
  const requestors = [];

  // jsonData.forEach((index) => {
  //   requestors.push({
  //     Requestor: index.Requestor,
  //   });
  // });
  jsonData.forEach((index) => {
    if (in_selectedServername) {
      if (index.ServerName === in_selectedServername) {
        requestors.push({
          Requestor: index.Requestor,
        });
      }
    }
    else if (in_selectedEnvrionment) {
      if (index.EnvironmentName === in_selectedEnvrionment) {
        requestors.push({
          Requestor: index.Requestor,
        });
      }
    }
    else{
      requestors.push({
        Requestor: index.Requestor,
      });
    }
  });

  //console.log("requestors::::get_requestors: ", requestors);
  return requestors;
}

function get_environments(in_tableRows,in_selectedServername,in_selectedRequestor) {
  //console.log("environments array : (get_environments):: ", in_selectedServername, in_selectedRequestor);
  var jsonData = in_tableRows;
  console.log("JSON data::", jsonData)
  const environments = [];

  jsonData.forEach((index) => {
    if (in_selectedServername && in_selectedRequestor) {
      if ( (index.ServerName.toLowerCase() === in_selectedServername.toLowerCase()) && (index.Requestor.toLowerCase() === in_selectedRequestor.toLowerCase()) ) {
        environments.push({
          Environment: index.EnvironmentName,
        });
      }
    }
    else if (in_selectedRequestor) {
      if (index.Requestor.toLowerCase() === in_selectedRequestor.toLowerCase()) {
        environments.push({
          Environment: index.EnvironmentName,
        });
      }
    } 
    else if (in_selectedServername) {
      if (index.ServerName.toLowerCase() === in_selectedServername.toLowerCase()) {
        environments.push({
          Environment: index.EnvironmentName,
        });
      }
    }
    else{
      environments.push({
        Environment: index.EnvironmentName,
      });
    }
  });

  //console.log("environments::::get_environment: ", environments);
  return environments;
}

export default SearchBar;
