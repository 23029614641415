import { Label, ComboBoxV2, TextFieldV2 } from "@verint/shared-react-components";
import OverlayText from "./OverlayText"
import '@verint/lux_icon_font/verint_lux.css';
import React, { useRef,useState,useEffect } from 'react';
import $ from 'jquery';

function Environment(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const anchorRef = useRef(null); // useRef<null | HTMLButtonElement>(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [open, setOpen] = useState(false);
    const environmentDisplay = get_environemnt_values(props);
    const tooltipText = "This is a test for overlay texture creation"
    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption.template_id);
        props.setEnvironmentSelected(selectedOption.template_id) 
        console.log("selectedOption",selectedOption.template_id)
    
    };
    useEffect(() => {
      
        $("[placeholder='--Select Environment --']").attr('required','')
    }, []);
    function handleClickButton(event) {
        setAnchorEl(event.currentTarget);
        setOpen(open => !open);
        event.preventDefault()
        return false;
      }

    function handleEnvironemntOnload(){
        $("[placeholder='--Select Environment --']").attr('required','')
    }  
    console.log("Environment Display:", environmentDisplay); 
    
    return (
        <>
           
              <tr className='reqForm_tablerow'>
                   <td className="td_row_leftvalue">
                            <Label
                                className="verint-regular-label"
                                htmlFor="TemplateId"
                            > Environment </Label><div className="required-field"></div>
                            <OverlayText tooltipText={"Use this option to select what type of lab. you wish to deploy. For example: BLUE (simulates an 'On Premise' environment) or EARTH (simulates a 'Cloud' environment). We have many application and technical labs to choose from."}></OverlayText>
                    </td>
                    <td className="td_row_rightvalue">
                    <ComboBoxV2
                        className="comboboxv2"
                        popperProps={{open:true}}
                        id="TemplateName"
                        style={{ paddingRight: "7%" }}                    

                        onChange={(_, value) => {
                            handleChange(value);
                            }}
                        options={environmentDisplay}
                        optionKey={'template_id'}
                        optionDisplay={'template_name'}
                        placeholder="--Select Environment --"                  
                        
                        onLoad={handleEnvironemntOnload}
                        textFieldProps={{                              
                            placeholder: '--Select Environment --'
                            }}
                        />
                        
                        <TextFieldV2 style={{display:"none"}}                            
                                    id="TemplateId"
                                    name='TemplateId'
                                    data-testid="verint-textfield"
                                    defaultValue={selectedOption}
                                    value={selectedOption}
                                    className="verint-hidden-textfield"
                                    
                                />
                    </td>
               </tr>
           
        </>
    );
}
function get_environemnt_values(props)
{
  var jsonData =props.environemntRecords
  const environment = [{
        template_id:"",
        template_name : "",
        order : 0
  }];
  for( var i =0; i < jsonData.Count; i++)
        {
            if(props.regionSelected != "us-east-2" &&  props.regionSelected != null )
            {
                if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].Region != undefined 
                && jsonData.Items[i].Region.includes(props.regionSelected))
                {
                    //console.log("this groupName:" + jsonData.Items[i].template_id);              
                
                    environment.push({
                        template_id:jsonData.Items[i].template_id,
                        template_name : jsonData.Items[i].template_name,
                        order :  jsonData.Items[i].order
                    })
                    environment.sort((a, b) => a.order - b.order )
                    
                }
            }
            else{
                if(jsonData.Items[i].group_name.includes(props.group))
                {
                             
                
                    environment.push({
                        template_id:jsonData.Items[i].template_id,
                        template_name : jsonData.Items[i].template_name,
                        order :  jsonData.Items[i].order
                    })
                    environment.sort((a, b) => a.order - b.order )
                    
                }
            }
        }
    return environment;
}

export default Environment;


