import { getScheduleDisplay } from '../initializeForm';
import React, { useState,useEffect } from 'react';
import {  Label, ComboBoxV2 ,TextFieldV2} from "@verint/shared-react-components";
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";
import $ from 'jquery';

function TimeZone(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const open = Boolean(anchorEl);

    // Get schedule display data
    var scheduleDisplay = getScheduleDisplay(props.group);
    console.log("schedule",scheduleDisplay)

    const optionsArray = React.Children.map(scheduleDisplay.props.children, (child) => ({
      key: child.props.value,
      value: child.props.children
  }));
  useEffect(() => {
            $("[placeholder='--Select TimeZone --']").attr('required','')
    }, []);
    console.log("option array", optionsArray.value)
    // Handle timezone change
    const handleTimezoneChange = (selectedOption) => {
        // Handle timezone change if needed
        console.log('Selected timezone:', selectedOption.value);
        setSelectedOption(selectedOption.key);
        
    };

    return (
        <>
          
              <tr className='reqForm_tablerow' >
                   <td className="td_row_leftvalue" >
                <Label
                    
                    className="verint-regular-label"
                    htmlFor="ScheduleName"
                >
                    Time Zone
                </Label><div className="required-field"></div>

                <OverlayText tooltipText={"Select the time zone that is closest to your region.The timezone determines what time your lab session shuts down each day."}></OverlayText></td>

                <td className="td_row_rightvalue">
                            <ComboBoxV2
                            className="comboboxv2"
                            name="ScheduleName"
                            id="ScheduleName"
                            optionKey="key" 
                            options={optionsArray}
                            optionDisplay={'value'}
                            onChange={(_, value) => {
                                handleTimezoneChange(value);
                              }}
                            placeholder='--Select TimeZone --'
                            textFieldProps={{                               
                                placeholder: '--Select TimeZone --'
                              }}
                        />
                        <TextFieldV2 style={{display:"none"}}                            
                                    id="ScheduleName"
                                    name='ScheduleName'
                                    data-testid="verint-textfield"
                                    defaultValue={selectedOption}
                                    value={selectedOption}
                                    className="verint-hidden-textfield"
                                    
                                />
               
               </td>
               </tr>
           
           
        </>
    );
}

export default TimeZone;