import React from 'react';
import './SystemInformationTable.css'
import '@verint/lux_icon_font/verint_lux.css';
import {
    SearchableContainer
  } from "@verint/shared-react-components";

function SystemInformationDescription(props){
    
    return(
        <>    
            <br></br>
            <SearchableContainer>    
                <div class='descContainer'>
                    <div class="systemInfoDescription" dangerouslySetInnerHTML={{ __html: props.description }}>
                        
                    </div>
                </div>   
            </SearchableContainer>
        </>
        );
}

export default SystemInformationDescription;